import React from 'react'
import {locationFormatter,translate} from '../../shared/utility'
import BicycleGreen from '../../assets/bicycle_green.svg'
import BicycleYellow from '../../assets/bicycle_yellow.svg'
import BicycleRed from '../../assets/bicycle_red.svg'

import classes from './FahrtenListItem.module.css'

const UpcomingFahrtenListItem = (props) => {

    return (
      <div className={classes.FahrtenListItem}>
          <div className={classes.Upper}>
              <ul>
                  <li className={classes.IdItem}>{props.fahrt.id}</li>                  
                  <li>{props.fahrt.end}</li>
                  <li className={classes.ItemRight}>{props.fahrt.hour < 10 ? "0"+props.fahrt.hour:""+props.fahrt.hour}:{props.fahrt.minute < 10 ? "0"+props.fahrt.minute:""+props.fahrt.minute}</li>
                  <li className={[classes.ItemRightEnd,classes.Green].join(" ")}>{props.fahrt.capacity}<img src={BicycleGreen}/></li>
              </ul>
          </div>
      </div>
    )

}
export default UpcomingFahrtenListItem