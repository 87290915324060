import React from 'react'

import ScrollSelector from './scrollSelector/ScrollSelector'

import classes from './TimeDial.module.css'

const TimeDial = (props) => {
    return (
      <div className={classes.TimeDial}>
          <ScrollSelector wheelId="hour-wheel" data={props.hours}  timeChanged={(index) => props.hoursChanged(index)}></ScrollSelector>
          <div className={classes.Seperator}>:</div>
          <ScrollSelector wheelId="minute-wheel" data={props.minutes}  timeChanged={(index) => props.minuteChanged(index)}></ScrollSelector>
      </div>
    )

}
export default TimeDial