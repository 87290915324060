import React,{Component} from 'react';
import './App.css';
import {connect} from 'react-redux'
import * as actions from './store/actions/index'
import Firebase from './db/firebase'
import iframeResizer from 'iframe-resizer'

import Routes from './routes/Routes'


class App extends Component{

  componentDidMount(){
    this.props.onSignInUser()
  }

  render(){

      
      

    return (
      <div className="App">
        <Routes></Routes>
      </div>
    );
  }
  
}

const mapStateToProps = state => {
    return {
        search: state.main.search
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSignInUser: () => dispatch(actions.logInUser()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
