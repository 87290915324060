import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {translate} from '../../shared/utility'

import SearchButton from '../../components/buttons/SearchButton'

import classes from "./Infobox.module.css"

class Infobox extends Component {

    state = {
     
    }

    onMoveToSettingsHandler = () => {
        this.props.history.push("/manuall")
    }

    render(){  
        const hour = this.props.time.hour
        const minute = this.props.time.minute
        return (
                <div className={classes.Infobox}>
                    <div className={classes.StartContainer}>
                        <h3>{this.props.start}</h3>
                        <SearchButton className="ChangeButton" action={this.onMoveToSettingsHandler}>{translate(this.props.lang,"ÄNDERN","CHANGE")}</SearchButton>
                    </div>
                    <div>
                        <h3>{hour < 10 ? "0"+hour:hour}:{minute < 10 ? "0"+minute:minute}</h3>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.main.lang
    }
}
export default withRouter(connect(mapStateToProps)(Infobox))