import React,{Component} from 'react'
import WheelPicker from 'react-wheelpicker'
import {getTimeArry} from '../../../../shared/utility'

import "./ScrollSelector.css"

class ScrollSelector extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: getTimeArry(this.props.wheelId === "hour-wheel" ? "hour":"minute"),
            defaultSelection: this.props.wheelId === "hour-wheel" ? new Date().getHours(): new Date().getMinutes()

        }
    }
    
    render(){
        return (
            <div>
            <div className={"selector-caret-top"}></div>
            <WheelPicker
                animation="flat"
                data={this.state.data}
                height={40}
                parentHeight={140}
                fontSize={38}
                defaultSelection={this.state.defaultSelection}
                updateSelection={(selectedIndex) => {
                    this.props.timeChanged(selectedIndex)
                    this.setState({defaultSelection: selectedIndex})
                }}
                scrollerId={this.props.wheelId}
            ></WheelPicker>
            <div className={"selector-caret-bottom"}></div>
            </div>
    )
    }
    

}
export default ScrollSelector