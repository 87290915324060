import React from 'react'
import {translate} from '../../shared/utility'

import TimeDial from './time/TimeDial'

import classes from './TimeSelector.module.css'

const TimeSelector = (props) => {

    return (
      <div className={classes.TimeSelector}>
          <p>{translate(props.lang,"Abfahrtszeit","Time")}</p>
          <div className={classes.TimeDial}>
            <TimeDial hours={props.hours}
             minutes={props.minutes} 
              hoursChanged={props.hourChanged}
               minuteChanged={props.minuteChanged}></TimeDial>
          </div>
      </div>
    )

}
export default TimeSelector