import React, { Component } from 'react';

class TaskTimer extends Component {

    state = {
        mainInterval: null
    }


    componentDidMount(){
        this.setState({mainInterval: setInterval(() => {
            this.props.task()
            console.group();
            console.log("%c Tasktimer log","color: purple");
            console.groupEnd()
        },this.props.loopTime*60000)})
    }

    componentWillUnmount(){
        clearInterval(this.state.mainInterval)
    }

    render(){  
        return null
    }
}
export default TaskTimer