import React, { Component } from 'react';
import {connect} from 'react-redux'
import {translate,removeDuplicateRoutes} from '../../../shared/utility'

import FahrtenListHeader from '../../../components/headers/FahrtenListHeader'
import FahrtenContentList from '../../../components/lists/FahrtenContentList'
import UpcomingFahrtenList from '../../../components/lists/UpcomingFahrtenList'
import Spinner from '../../../components/loading/Spinner'

import classes from './FahrtenList.module.css'

class FahrtenList extends Component {

    state = {
     
    }

    render(){  

        let fahrtList = (<React.Fragment>
                <FahrtenContentList lang={this.props.lang} list={this.props.routes} upcomingExist={this.props.upcomingRoutes.length !== 0}></FahrtenContentList>
                <UpcomingFahrtenList lang={this.props.lang} list={removeDuplicateRoutes(this.props.upcomingRoutes,this.props.routes)}></UpcomingFahrtenList>
            </React.Fragment>)
        return (
                <div className={classes[this.props.className]}>
                    <FahrtenListHeader className={this.props.headerClassName} lang={this.props.lang}></FahrtenListHeader>
                    <div className={classes.ScannedMobile}>
                        {this.props.startSearch ? this.props.loading ? <Spinner></Spinner>:fahrtList:
                        <h1 className={classes.EmptyHeading}>{translate(this.props.lang,"Kein Abfahrtsort gewählt","No departure point selected")}</h1>}
                    </div>
                   
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        startSearch: state.main.startSearch,
        loading: state.main.loading,
        lang: state.main.lang,
        upcomingRoutes: state.main.upcomingRoutes,
        routes: state.main.routes
    }
}

export default connect(mapStateToProps)(FahrtenList)