import * as actionTypes from './actionTypes';
import Firebase from '../../db/firebase'

//constants


//sync action creators

export const getRoutesStart = () => {
    return {
        type: actionTypes.GET_ROUTES_START
    }
}

export const getRoutesSuccess = (data) => {
    
    return {
        type: actionTypes.GET_ROUTES_SUCCESS,
        data: data
    }
}

export const getRoutesFail = (error) => {
    console.log(error)
    return {
        type: actionTypes.GET_ROUTES_FAIL,
        error: error
    }
}

export const updateRoutes = (fahrten) => {
    return {
        type: actionTypes.UPDATE_ROUTES,
        data: fahrten
    }
}

export const setDirection = (direction) => {
    return {
        type: actionTypes.SET_DIRECTION,
        data: direction
    }
}

export const setStation = (station) => {
    return {
        type: actionTypes.SET_STATION,
        data: station
    }
}

export const setHour = (hour) => {
    return {
        type: actionTypes.SET_HOUR,
        data: hour
    }
}

export const setMinute = (minute) => {
    return {
        type: actionTypes.SET_MINUTE,
        data: minute
    }
}

export const startSearch = (search) => {
    return {
        type: actionTypes.START_SEARCH,
        data: search
    }
}

export const getStopsStart = () => {
    return {
        type: actionTypes.GET_STOPS_START
    }
}

export const getStopsSuccess = (data) => {
    return {
        type: actionTypes.GET_STOPS_SUCCESS,
        data: data
    }
}

export const getStopsFail = (error) => {
    return {
        type: actionTypes.GET_STOPS_FAIL,
        error: error
    }
}

export const setLang = (lang) => {
    return {
        type: actionTypes.SET_LANG,
        data: lang
    }
}

export const setSite = (site) => {
    return {
        type: actionTypes.SET_SITE,
        data: site
    }
}

export const getUpcomingRoutesStart = () => {
    return {
        type: actionTypes.GET_UPCOMING_ROUTES_START
    }
}

export const getUpcomingRoutesSuccess = (data) => {
    
    return {
        type: actionTypes.GET_UPCOMING_ROUTES_SUCCESS,
        data: data
    }
}

export const getUpcomingRoutesFail = (error) => {
    return {
        type: actionTypes.GET_UPCOMING_ROUTES_FAIL,
        error: error
    }
}

export const updateUpcomingRoutes = (data) => {
    return {
        type: actionTypes.UPDATE_UPCOMING_ROUTES,
        data: data
    }
}

//async action creators

export const getRoutes = (direction,hour,minute,station) => {
    console.log(direction,hour,minute,station)
    return dispatch => {
        dispatch(getRoutesStart())
        Firebase.getActiveFahrten(direction,hour,minute,station)
        .then(res => dispatch(getRoutesSuccess(res)))
        .catch(err => dispatch(getRoutesFail(err)))
    }
}

export const getStops = () => {
    return dispatch => {
        dispatch(getStopsStart())
        Firebase.getBusStations()
        .then(res => dispatch(getStopsSuccess(res)))
        .catch(err => dispatch(getStopsFail(err)))
    }
}

export const getUpcomingRoutes = (direction,hour,minute,station) => {
    return dispatch => {
        dispatch(getUpcomingRoutesStart())
        Firebase.getUpcomimgFahrten(direction,hour,minute,station)
        .then(res => dispatch(getUpcomingRoutesSuccess(res)))
        .catch(err => dispatch(getUpcomingRoutesFail(err)))
    }
}


