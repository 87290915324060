import React, { Component } from 'react';
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'
import {translate} from '../../shared/utility'

import FahrtenHeader from '../../components/headers/FahrtenHeader'
import FahrtenList from './fahrtenList/FahrtenList'

import classes from './Fahrten.module.css'

class Fahrten extends Component {

    state = {
     
    }

    componentDidMount(){

    }

    render(){  
        return (
                <div className={classes.Fahrten}>
                    <FahrtenHeader>{translate(this.props.lang,"Nächste Fahrten","Next ride")}</FahrtenHeader>
                    <FahrtenList 
                    headerClassName={"FahrtenListHeader"}
                     className={"FahrtenList"} routes={this.props.routes}></FahrtenList>
                     <div className={classes.SecretDiv} ref={this.props.scrollRef}></div>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        startSearch: state.main.startSearch,
        routes: state.main.routes,
        lang: state.main.lang
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Fahrten)