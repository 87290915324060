import * as firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/firebase-auth'

const firebaseConfig = {
    apiKey: "AIzaSyCZq2WIpqC7l_9Xe3YSzMW8O1D3YfbJB4Y",
    authDomain: "radbusapp.firebaseapp.com",
    databaseURL: "https://radbusapp.firebaseio.com",
    projectId: "radbusapp",
    storageBucket: "radbusapp.appspot.com",
    messagingSenderId: "196308129026",
    appId: "1:196308129026:web:d7d7cca5ad636fdaf89e1b"
  }

firebase.initializeApp(firebaseConfig)

export default firebase