import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility'

//initialState

const initialState = {
    loading: false,
    routes: [],
    stops: [],
    searchStarted: false,
    upcomingRoutes: [],
    search: {
        direction: "4194 / 8352 Imst - Timmelsjoch",
        station: "Haiming Gemeinde",
        time: {
            hour: 0,
            minute: 0
        }
    },
    error: null,
    lang: "de",
    site: ""
}

//constants

//helper functions
const getRoutesStart = (state,action) => {
    return updateObject(state,{loading: true,error: null})
}

const getRoutesSuccess = (state,action) => {
    return updateObject(state,{loading: false, routes: action.data, error: null})
}

const getRoutesFail = (state,action) => {
    return updateObject(state,{loading: false,error: action.error})
}

const updateRoutes = (state,action) => {
    return updateObject(state,{routes: action.data})
}

const setDirection = (state,action) => {
    return updateObject(state,{search: updateObject(state.search,{direction: action.data})})
}

const setStation = (state,action) => {
    return updateObject(state,{search: updateObject(state.search,{station: action.data})})
}

const setHour = (state,action) => {
    return updateObject(state,{search: updateObject(state.search,{time: updateObject(state.search.time,{hour: action.data})})})
}

const setMinute = (state,action) => {
    return updateObject(state,{search: updateObject(state.search,{time: updateObject(state.search.time,{minute: action.data})})})
}

const getStopsStart = (state,action) => {
    return updateObject(state,{loading: true,error: null})
}

const getStopsSuccess = (state,action) => {
    return updateObject(state,{stops: action.data, error: null, loading: false})
}

const getStopsFail = (state,action) => {
    return updateObject(state,{error: action.error, loading: false})
}

const startSearch = (state,action) => {
    return updateObject(state,{startSearch: true})
}

const setLang = (state,action) => {
    return updateObject(state,{lang: action.data})
}

const setSite = (state,action) => {
    return updateObject(state,{site: action.data})
}

const getUpcomingRoutesStart = (state,action) => {
    return updateObject(state,{loading: true, error: null})
}

const getUpcomingRoutesSuccess = (state,action) => {
    return updateObject(state,{loading: false, upcomingRoutes: action.data, error: null})
}

const getUpcomingRoutesFail = (state,action) => {
    return updateObject(state,{loading: false, error: action.error})
}

const updateUpComingRoutes = (state,action) => {
    return updateObject(state,{upcomingRoutes: action.data})
}

//reducer

const mainReducer = (state = initialState,action) => {
    switch(action.type){
        case(actionTypes.GET_ROUTES_START) : return getRoutesStart(state,action)
        case(actionTypes.GET_ROUTES_SUCCESS) : return getRoutesSuccess(state,action)
        case(actionTypes.GET_ROUTES_FAIL) : return getRoutesFail(state,action)
        case(actionTypes.UPDATE_ROUTES) : return updateRoutes(state,action)
        case(actionTypes.SET_DIRECTION) : return setDirection(state,action)
        case(actionTypes.SET_STATION) : return setStation(state,action)
        case(actionTypes.SET_HOUR) : return setHour(state,action)
        case(actionTypes.SET_MINUTE) : return setMinute(state,action)
        case(actionTypes.START_SEARCH) : return startSearch(state,action)
        case(actionTypes.GET_STOPS_START) : return getStopsStart(state,action)
        case(actionTypes.GET_STOPS_SUCCESS) : return getStopsSuccess(state,action)
        case(actionTypes.GET_STOPS_FAIL) : return getStopsFail(state,action)
        case(actionTypes.SET_LANG) : return setLang(state,action)
        case(actionTypes.SET_SITE) : return setSite(state,action)
        case(actionTypes.GET_UPCOMING_ROUTES_START) : return getUpcomingRoutesStart(state,action)
        case(actionTypes.GET_UPCOMING_ROUTES_SUCCESS) : return getUpcomingRoutesSuccess(state,action)
        case(actionTypes.GET_UPCOMING_ROUTES_FAIL) : return getUpcomingRoutesFail(state,action)
        case(actionTypes.UPDATE_UPCOMING_ROUTES) : return updateUpComingRoutes(state,action)
        default : return state
    }
}

export default mainReducer