import * as actionTypes from './actionTypes';
import Firebase from '../../db/firebase'
//constants


//sync action creators
export const logInUserStart = () => {
    return {
        type: actionTypes.LOG_IN_USER_START
    }
}

export const logInUserSuccess = (data) => {
    
    return {
        type: actionTypes.LOG_IN_USER_SUCCESS,
        data: data
    }
}

export const logInUserFail = (error) => {
    return {
        type: actionTypes.LOG_IN_USER_FAIL,
        error: error
    }
}

//async action creators

export const logInUser = () => {
    return dispatch => {
        dispatch(logInUserStart())
        Firebase.signInAnon()
        .then(res => dispatch(logInUserSuccess(res)))
        .catch(err => dispatch(logInUserFail(err?.message)))
    }
}
