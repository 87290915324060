import React from 'react'

import classes from './FahrtenHeader.module.css'

const FahrtenHeader = (props) => {

    return (
      <div className={classes.FahrtenHeader}>
          <h1>{props.children}</h1>
      </div>
    )

}
export default FahrtenHeader