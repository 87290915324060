export const GET_ROUTES_START = "GET_ROUTES_START"
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS"
export const GET_ROUTES_FAIL = "GET_ROUTES_FAIL"

export const GET_STOPS_START = "GET_STOPS_START"
export const GET_STOPS_SUCCESS = "GET_STOPS_SUCCESS"
export const GET_STOPS_FAIL = "GET_STOPS_FAIL"

export const UPDATE_ROUTES = "UPDATE_ROUTES"

export const SET_DIRECTION = "SET_DIRECTION"
export const SET_STATION = "SET_STATION"
export const SET_HOUR = "SET_HOUR"
export const SET_MINUTE = "SET_MINUTE"

export const START_SEARCH = "START_SEARCH"

export const SET_LANG = "SET_LANG"
export const SET_SITE = "SET_SITE"

export const GET_UPCOMING_ROUTES_START = "GET_UPCOMING_ROUTES_START"
export const GET_UPCOMING_ROUTES_SUCCESS = "GET_UPCOMING_ROUTES_SUCCESS"
export const GET_UPCOMING_ROUTES_FAIL = "GET_UPCOMING_ROUTES_FAIL"

export const UPDATE_UPCOMING_ROUTES = "UPDATE_UPCOMING_ROUTES"


/* Auth */

export const LOG_IN_USER_START = "LOG_IN_USER_START"
export const LOG_IN_USER_SUCCESS = "LOG_IN_USER_SUCCESS"
export const LOG_IN_USER_FAIL = "LOG_IN_USER_FAIL"