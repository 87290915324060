import React from 'react'
import {translate} from '../../shared/utility'

import UpcomingFahrtenListItem from '../boxes/UpcomingFahrtenListItem'

import classes from './UpcomingFahrtenList.module.css'

const UpcomingFahrtenList = (props) => {

    return (
      <div>
          {/* <h4 className={classes.Header}>{translate(props.lang,"Anstehende Fahrten","Upcoming trips")}</h4> */}
          {props.list.length !== 0 && props.list.map(el => {
              return <UpcomingFahrtenListItem fahrt={el}></UpcomingFahrtenListItem>
          })}
      </div>
    )

}
export default UpcomingFahrtenList