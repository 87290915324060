import React from 'react'
import {translate} from '../../shared/utility'

import FahrtenListItem from '../boxes/FahrtenListItem'

import classes from './FahrtenContentList.module.css'

const FahrtenContentList = (props) => {


    let placeholderText = null
    if(!props.upcomingExist){
        placeholderText = <p className={classes.NoItems}>{translate(props.lang,"Derzeit keine Bus Shuttles unterwegs","Currently no Bus Shuttles available")}</p>
    }

    return (
      <div>
          {props.list.length !== 0 ? props.list.map((el,index) => {
              return <FahrtenListItem key={el.docId} id={el.id} end={el.endPoint}
               currentLocation={el.currentLocation} hour={el.hour} lang={props.lang}
               minute={el.minute} count={el.count} capacity={el.capacity}></FahrtenListItem>
          }):placeholderText}
      </div>
    )

}
export default FahrtenContentList