import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility'

//initialState

const initialState = {
    user: null,
    loading: false,
    error: null
}

//constants

//helper functions
const logInUserStart = (state,action) => {
    return updateObject(state,{loading: true,error: null})
}

const logInUserSuccess = (state,action) => {
    return updateObject(state,{loading: false,user: action.data,error: null})
}

const logInUserFail = (state,action) => {
    return updateObject(state,{loading: false,error: action.error})
}

//reducer

const authReducer = (state = initialState,action) => {
    switch(action.type){
        case(actionTypes.LOG_IN_USER_START) : return logInUserStart(state,action)
        case(actionTypes.LOG_IN_USER_SUCCESS) : return logInUserSuccess(state,action)
        case(actionTypes.LOG_IN_USER_FAIL) : return logInUserFail(state,action)
        default : return state
    }
}

export default authReducer