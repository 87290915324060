import React from 'react'
import {changeDirectionDisplayValue} from '../../shared/utility'

import classes from './Dropdown.module.css'

const Dropdown = (props) => {

    return (
      <div className={classes.Dropdown}>
          <h3>{props.title}</h3>
          <select defaultValue={props.defaultSelect} onChange={(event) => props.changed(event.target.value)}>
                {props.items.map((el,index) => {
                    return <option key={index} value={el}>{props.direction ? changeDirectionDisplayValue(el):el}</option>
                })}
          </select>
      </div>
    )

}
export default Dropdown