import React, { Component,createRef } from 'react';
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'
import {getCurrentTime,translate} from '../../shared/utility'
import Firebase from '../../db/firebase'

import SettingsHeader from '../../components/headers/SettingsHeader';
import Infobox from '../../containers/scanned/Infobox'
import FahrtenList from '../../containers/fahrten/fahrtenList/FahrtenList'
import TaskTimer from '../../containers/misc/TaskTimer'
import FahrtenHeader from '../../components/headers/FahrtenHeader'

import classes from './Scanned.module.css';

class Scanned extends Component {

    state = {
        
    }
    
    

    componentDidMount(){
        const direction = this.props.match.params.direction === "imst" ? "4194 / 8352 Timmelsjoch - Imst":"4194 / 8352 Imst - Timmelsjoch"
        let station = this.props.match.params.station
        let lang = this.props.match.params.lang
        if(lang){
            this.props.onSetLang(lang)
        }else{
            this.props.onSetLang("de")
        }
        
        if(station === "Längenfeld Edelweiß abzw Gries"){
            station = "Längenfeld Edelweiß/abzw Gries"
        }
        const time = getCurrentTime()
        this.props.onGetStops()
        this.props.onSetHour(time.hour)
        this.props.onSetMinute(time.minute)
        this.props.onSetDirection(direction)
        this.props.onSetStation(station)
        this.props.onGetActiveFahrten(direction,time.hour,time.minute,station)
        Firebase.fahrtenListener(direction,time.hour,time.minute,station,(res) => {
            this.props.onUpdateFahrten(res)
        })
        this.props.onStartSearch()

        this.props.onGetUpcomingRoutes(direction,time.hour,time.minute,station)
    }

    updateTimeTaskHandler = () => {
        const time = getCurrentTime()
        this.props.onSetHour(time.hour)
        this.props.onSetMinute(time.minute)
        console.log("updateTimeTaskHandler")
        this.props.onGetActiveFahrten(this.props.search.direction,time.hour,
            time.minute,this.props.search.station)
    }


    render(){  
        return (
                <div className={classes.Scanned}>
                    <div className={classes.Header}>
                       {/* <SettingsHeader>Bike Shuttle</SettingsHeader> */}
                    </div>
                    <Infobox start={this.props.search.station} time={this.props.search.time}></Infobox>
                    <div className={classes.FahrtenList}>
                        <FahrtenHeader>{translate(this.props.lang,"Nächste Fahrten","Next ride")}</FahrtenHeader>
                        <FahrtenList headerClassName={"FahrtenListHeader"} className={"FahrtenList"} routes={this.props.routes}></FahrtenList>
                    </div>
                    <TaskTimer loopTime={1} task={this.updateTimeTaskHandler}></TaskTimer>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        search: state.main.search,
        routes: state.main.routes,
        lang: state.main.lang
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetStops: () => dispatch(actions.getStops()),
        onSetDirection: (direction) => dispatch(actions.setDirection(direction)),
        onSetStation: (station) => dispatch(actions.setStation(station)),
        onSetHour: (hour) => dispatch(actions.setHour(hour)),
        onSetMinute: (minute) => dispatch(actions.setMinute(minute)),
        onStartSearch: () => dispatch(actions.startSearch()),
        onGetActiveFahrten: (direction,hour,minute,station) => dispatch(actions.getRoutes(direction,hour,minute,station)),
        onUpdateFahrten: (fahrten) => dispatch(actions.updateRoutes(fahrten)),
        onSetLang: (lang) => dispatch(actions.setLang(lang)),
        onGetUpcomingRoutes: (direction,hour,minute,station) => dispatch(actions.getUpcomingRoutes(direction,hour,minute,station))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Scanned)