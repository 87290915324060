import React, { Component } from 'react';
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'
import Firebase from '../../db/firebase/index'

import Fahrten from '../../containers/fahrten/Fahrten'
import Settings from '../../containers/settings/Settings'

import classes from './Manuall.module.css'

class Manuall extends Component {

    constructor(){
        super();
        this.scrollRef = React.createRef()
    }

    state = {
     
    }

    componentDidMount(){
        let lang = this.props.match.params.lang
        let site = this.props.match.params.site
        this.props.onSetSite(site)
        console.log(lang)
        if(lang){
            this.props.onSetLang(lang)
        }else{
            this.props.onSetLang("de")
        }
        
        console.log("in Manuall")
        this.props.onGetStops()
        this.props.onSetDirection(this.props.search.direction)
        this.props.onSetStation(this.props.search.station)
    }

    onScrollActionHandler = () => {
        window.scrollTo(0,this.scrollRef.current.offsetTop)
    }

    render(){  
        return (
                <div className={classes.Container}>
                    <Settings scrollAction={this.onScrollActionHandler}></Settings>
                    <Fahrten scrollRef={this.scrollRef}></Fahrten>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        search: state.main.search
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateFahrten: (fahrten) => dispatch(actions.updateRoutes(fahrten)),
        onSetDirection: (direction) => dispatch(actions.setDirection(direction)),
        onSetStation: (station) => dispatch(actions.setStation(station)),
        onSetLang: (lang) => dispatch(actions.setLang(lang)),
        onSetSite: (site) => dispatch(actions.setSite(site)),
        onGetStops: () => dispatch(actions.getStops())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Manuall)