import React from 'react'
import {translate} from '../../shared/utility'

import classes from './FahrtenListHeader.module.css'

const FahrtenListHeader = (props) => {

    return (
      <div className={classes[props.className]}>
          <ul>
              <li>Bus</li>
              <li className={classes.EndHeader}>{translate(props.lang,"Nach","to")}</li>
              <li className={classes.ListItemRight}>{translate(props.lang,"Zeit","Time")}</li>
              <li className={classes.LastItem}>{translate(props.lang,"Kapazität *","Capacity *")}</li>
          </ul>
      </div>
    )

}
export default FahrtenListHeader