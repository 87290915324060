
//constants

let gemeinden = [
    {plz: "6460",name: "Gemeinde Imst"},
    {plz: "6463",name: "Karrösten"},
    {plz: "6462",name: "Karres"},
    {plz: "6426",name: "Roppen"},
    {plz: "6432",name: "Sautens"},
    {plz: "6433",name: "Oetz"},
    {plz: "6441",name: "Umhausen"},
    {plz: "6444",name: "Längenfeld"},
    {plz: "6450",name: "Sölden"},
    {plz: "6456",name: "Obergurgl"},
    {plz: "6430",name: "Ötztal Bahnhof"},
    {plz: "6175",name: "Kematen"},
]



export const updateObject = (oldObject, updatedProp) => {
    return {
        ...oldObject,
        ...updatedProp
    }
}

export const getCurrentTime = () => {
    let currentTime = new Date()
    let hour = currentTime.getHours()
    let minute = currentTime.getMinutes()
    return {hour: hour,minute: minute}
}


export const  checkFahrt = (fahrt,timeIndex,station) => {
    let arrival = fahrt.arrival
    let stops = arrival.filter(el => el.station === station && el.timeIndex >= timeIndex)
    if(stops.length !== 0){
        let time = stops[stops.length-1]
        return {passed: true,hour: time.hour,minute: time.minute}
    }else{
        return {passed: false}
    }
}


export const getTimeArry = (timeValue) => {
    let timeArray = []
    if(timeValue === "hour"){
        for(let t = 0; t < 24;t++){
            if(t < 10){
                timeArray.push("0"+t)
            }else{
                timeArray.push(""+t)
            }
        }
    }else {
        for(let t = 0; t < 60;t++){
            if(t < 10){
                timeArray.push("0"+t)
            }else{
                timeArray.push(""+t)
            }
        }
    }
    
    return timeArray
}


export const locationFormatter = (location) => {
    let formatA = location.split(",")
    if(formatA.length === 3){
        let ort = formatA[1].split(" ")
        let result = ""
        if(ort.length === 2){
            let newOrt = gemeinden.find(el => el.plz === ort[1])
            if(newOrt){
                return newOrt.name
            }else{
                return ort[0]
            }
            
        }else{
            for(let o = 2;o < ort.length; o++){
                result += ort[o]+" ";
           }
           return result
        }
        
        
    }else {
        let ort = formatA[0].split(" ")
        return `${ort[0]}`
    }
    
}

export const filterStops = (direction,stops) => {
    let newStops = []
    if(direction === "4194 / 8352 Imst - Timmelsjoch"){
       newStops = stops.filter(el => el.timmelsjoch === true)
    }else{
       newStops = stops.filter(el => el.imst === true)
    }
     
    console.log(stops)
    return newStops.map(el => el.name)
}


export const translate = (lang,de,en) => {
    return lang === "de" ? de:en
}

export const setSiteCSS = (site) => {
    return site === "oetz"
}

export const changeDirectionDisplayValue = (value) => {
    return value === "4194 / 8352 Imst - Timmelsjoch" ? "4194 / 8352 Imst – Obergurgl" : "4194 / 8352 Obergurgl – Imst"
}

export const removeDuplicateRoutes = (upcomingRoutes,routes) => {
    let newUpcomingRoutes = []

    if(routes.length === 0){return upcomingRoutes}

    for(let upcomingRoute of upcomingRoutes){
        let upcomingTimeIndex = (upcomingRoute.hour * 60) + upcomingRoute.minute
        for(let route of routes){
            let routeTimeIndex = (route.hour * 60) + route.minute
            if(upcomingTimeIndex !== routeTimeIndex){
                newUpcomingRoutes.push(upcomingRoute)
            }
        }
    }

    return newUpcomingRoutes
}