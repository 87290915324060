import React, { Component } from 'react';
import {Switch,Route,Redirect} from 'react-router-dom'

import Manuall from '../pages/manuall/Manuall'
import Scanned from '../pages/scanned/Scanned'

class Routes extends Component {

    state = {
     
    }

    render(){  
        return (
                <Switch>
                    <Route path="/scanned/:direction/:station/:lang?" exact component={Scanned}></Route>
                    <Route path="/manuall/:lang?" component={Manuall}></Route>
                    <Redirect from="/" to="/manuall"></Redirect>
                </Switch>
        )
    }
}
export default Routes