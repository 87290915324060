import React, { Component } from 'react';
import {connect} from 'react-redux'
import {setSiteCSS} from '../../shared/utility'

import SettingsHeader from '../../components/headers/SettingsHeader'
import Form from './form/Form'

import classes from './Settings.module.css'

class Settings extends Component {

    state = {
       
    }

    

    render(){  
        return (
                <div className={classes.Settings}>
                    {/* <SettingsHeader>Bike Shuttle</SettingsHeader> */}
                    <Form scrollAction={this.props.scrollAction}></Form>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site: state.main.site
    }
}

export default connect(mapStateToProps)(Settings)