import firebase from './FirebaseConfig'
import {checkFahrt} from '../../shared/utility'

class FirebaseManager {


    db = firebase.firestore()
    auth = firebase.auth()
    currentDirection = ""

    listeners = {
        upcomingFahrtenListener: "upcomingFahrtenListener"
    }

    getActiveFahrten = (direction,hour,minute,station) => {
        let fahrten = []
        let date = new Date()
        let filterDate = new Date()
        date.setHours(date.getHours()-4)
        let timeIndex = (filterDate.getHours() * 60) + filterDate.getMinutes()
        return new Promise((resolve,reject) => {
            const fahrtenRef = this.db.collection("fahrt")
            .where("direction","==",direction)
            .where("date",">=",date)
            .get()
            .then(docs => {
                docs.forEach(doc => {
                    let newDoc = checkFahrt(doc.data(),timeIndex,station)
                    if(newDoc.passed){
                        if(!fahrten.some(el => el.routeId === doc.data().routeId)){
                            fahrten.push({...doc.data(),hour: newDoc.hour,minute: newDoc.minute,docId: doc.id})
                        }else {
                            let oldFahrt = fahrten.find(el => el.routeId === doc.data().routeId)
                            let oldFahrtIndex = fahrten.findIndex(el => el.routeId === doc.data().routeId)
                            let newFahrt = doc.data()
                            if(oldFahrt.date <= newFahrt.date){
                                fahrten[oldFahrtIndex] = {...newFahrt,hour: newDoc.hour,minute: newDoc.minute,docId: doc.id}
                            }else{

                            }

                        }
                    }
                    
                })
                fahrten.sort((a,b) => ((a.hour * 60) + a.minute) - ((b.hour * 60) + b.minute))
                resolve(fahrten)
            }).catch(err => reject(err))
        })
    }

    getBusStations = () => {
        return new Promise((resolve,reject) => {
            const stationRef = this.db.collection("stops")
            .where("bike","==",true)
            .orderBy("name","asc")
            .get()
            .then(docs => {
                let stations = []
                docs.forEach(doc => {
                    stations.push({...doc.data(),docId: doc.id})
                })
                resolve(stations)
            }).catch(err => console.log(err))

        })
    }

    signInAnon = () => {
        return new Promise((resolve,reject) => {
            this.auth.signInAnonymously()
            .then(res => resolve(res.user))
            .catch(err => reject(err))
        })
    }

    getUpcomimgFahrten = (direction,hour,minute,station) => {
        return new Promise((resolve,reject) => {
            let result = []
            let routeDirection = direction === "4194 / 8352 Imst - Timmelsjoch" ? "Timmelsjoch" : "Imst";
            let filterDate = new Date()
            let timeIndex = (hour * 60) + minute
            this.db.collection("routes").where("direction","==",routeDirection).get()
            .then(docs => {
                docs.forEach(doc => {
                    let potentialRoute = checkFahrt(doc.data(),timeIndex,station)
                    if(potentialRoute.passed){
                        result.push({...doc.data(),hour: potentialRoute.hour,minute: potentialRoute.minute,docId: doc.id})
                    }
                })
                result.sort((a,b) => {
                    let timeIndexA = (a.hour * 60) + a.minute
                    let timeIndexB = (b.hour * 60) + b.minute
                    return timeIndexA - timeIndexB
                })
                resolve(result.slice(0,5))
            }).catch(err => reject(err))
        })
    }

    //Db listener

    //unsubscribe listeners

    unsubscribeListener = (name) => {
        switch(name){
            case "upcomingFahrtenListener":
                if(typeof this.upcomingFahrtenListener === "function"){
                    this.upcomingFahrtenListener()
                }
                break;
            default: console.error("unknown listener: ",name)
        }
    }

    fahrtenListener = (direction,hour,minute,station,cb) => {
            this.currentDirection = direction
            let fahrten = []
            let date = new Date()
            let filterDate = new Date()
            date.setHours(date.getHours()-4)
            let timeIndex = (filterDate.getHours() * 60) + filterDate.getMinutes()
            const fahrtenRef = this.db.collection("fahrt")
            fahrtenRef.where("direction","==",direction)
            .where("date",">=",date)
            .onSnapshot(snap => {
                snap.forEach(doc => {
                    let newDoc = checkFahrt(doc.data(),timeIndex,station)
                    if(newDoc.passed){
                        if(!fahrten.some(el => el.routeId === doc.data().routeId)){
                            fahrten.push({...doc.data(),hour: newDoc.hour,minute: newDoc.minute,docId: doc.id})
                        }else {
                            let oldFahrt = fahrten.find(el => el.routeId === doc.data().routeId)
                            let oldFahrtIndex = fahrten.findIndex(el => el.routeId === doc.data().routeId)
                            let newFahrt = doc.data()
                            if(oldFahrt.date <= newFahrt.date){
                                fahrten[oldFahrtIndex] = {...newFahrt,hour: newDoc.hour,minute: newDoc.minute,docId: doc.id}
                            }else{

                            }

                        }
                        
                    }
                })
                fahrten.sort((a,b) => ((a.hour * 60) + a.minute) - ((b.hour * 60) + b.minute))
                if(this.currentDirection === direction){
                    cb(fahrten)
                }
               
                fahrten = []
            })
    }

    upcomingFahrtenListener = (direction,hour,minute,station,cb) => {

            let result = []
            let routeDirection = direction === "4194 / 8352 Imst - Timmelsjoch" ? "Timmelsjoch" : "Imst";
            let filterDate = new Date()
            let timeIndex = (hour * 60) + minute
            this.upcomingFahrtenListener = this.db.collection("routes").where("direction","==",routeDirection).onSnapshot(snap => {
                snap.forEach(doc => {
                    let potentialRoute = checkFahrt(doc.data(),timeIndex,station)
                    if(potentialRoute.passed){
                        result.push({...doc.data(),hour: potentialRoute.hour,minute: potentialRoute.minute,docId: doc.id})
                    }
                })
            })
            result.sort((a,b) => {
                let timeIndexA = (a.hour * 60) + a.minute
                let timeIndexB = (b.hour * 60) + b.minute
                return timeIndexA - timeIndexB
            })
            cb(result.slice(0,5))
    }

}


export default FirebaseManager