import React, { Component } from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions/index'
import Firebase from '../../../db/firebase'
import {filterStops,translate} from '../../../shared/utility'

import Dropdown from '../../../components/inputs/Dropdown'
import TimeSelector from '../../../components/inputs/TimeSelector'
import SearchButton from '../../../components/buttons/SearchButton'

import classes from './Form.module.css'

class Form extends Component {

    state = {
        direction: ["4194 / 8352 Imst - Timmelsjoch","4194 / 8352 Timmelsjoch - Imst"],
        hours: [],
        minutes: []
    }

    componentDidMount(){
        this.initTimeValues()
        this.props.onSetDirection("4194 / 8352 Imst - Timmelsjoch")
        this.props.onSetStation("Haiming Gemeinde")
    }

    initTimeValues = () => {
        let hours = []
        let minutes = []
        for(let h=0;h <= 23;h++){
            if(h < 10){
                hours.push("0"+h)
            }else{
                hours.push(""+h)
            }
            
        }
        for(let m=0;m <= 59;m++){
            if(m < 10){
                minutes.push("0"+m)
            }else{
                minutes.push(""+m)
            }
            
        }

        this.setState({hours: hours,minutes: minutes})
    }
    onDropDownChangedHandler = (index,type) => {
        if(type === "direction"){
            this.props.onSetDirection(index)
        }else {
            this.props.onSetStation(index)
        }
    }

    onHourChangedHandler = (index) => {
        this.props.onSetHour(index)
    }

    onMinuteChangedHandler = (index) => {
        this.props.onSetMinute(index)
    }

    onStartSearchHandler = () => {
        this.props.onStartSearch()
        this.props.onGetActiveRoutes(this.props.search.direction,
            this.props.search.time.hour,this.props.search.time.minute,this.props.search.station)
            console.log("Start",this.props.search.direction)
        Firebase.fahrtenListener(this.props.search.direction,
            this.props.search.time.hour,this.props.search.time.minute,this.props.search.station,(res) => {
            console.log("onStartSearchHandler",this.props.search.direction)
            this.props.onUpdateFarten(res)
        })
        // this.props.scrollAction()

        this.props.onGetUpcomingRoutes(this.props.search.direction,
            this.props.search.time.hour,this.props.search.time.minute,this.props.search.station)
        
    }

    componentWillUnmount(){
        
    }

    render(){  
        
        return (
                <div className={classes.Form}>
                    <Dropdown title={translate(this.props.lang,"Richtung","Direction")} direction defaultSelect={this.props.search.direction} items={this.state.direction} changed={(value) => this.onDropDownChangedHandler(value,"direction")}></Dropdown>
                    <Dropdown title={translate(this.props.lang,"Abfahrtsort","Departure point")} defaultSelect={this.props.search.station} items={filterStops(this.props.search.direction,this.props.stops)} changed={(value) => this.onDropDownChangedHandler(value,"station")}></Dropdown>
                    <TimeSelector hours={this.state.hours}
                     minutes={this.state.minutes} lang={this.props.lang} 
                       hourChanged={this.onHourChangedHandler}
                       minuteChanged={this.onMinuteChangedHandler}></TimeSelector>
                    <SearchButton className={"SearchButton"} action={this.onStartSearchHandler}>{translate(this.props.lang,"Suche","Search")}</SearchButton>
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        stops: state.main.stops,
        search: state.main.search,
        lang: state.main.lang
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetDirection: (direction) => dispatch(actions.setDirection(direction)),
        onSetStation: (station) => dispatch(actions.setStation(station)) ,
        onSetHour: (hour) => dispatch(actions.setHour(hour)),
        onSetMinute: (minute) => dispatch(actions.setMinute(minute)),
        onStartSearch: () => dispatch(actions.startSearch()),
        onGetActiveRoutes: (direction,hour,minute,station) => dispatch(actions.getRoutes(direction,hour,minute,station)),
        onUpdateFarten: (fahrten) => dispatch(actions.updateRoutes(fahrten)),
        onGetUpcomingRoutes: (direction,hour,minute,station) => dispatch(actions.getUpcomingRoutes(direction,hour,minute,station)),
        onUpdateUpcomingRoutes: (routes) => dispatch(actions.updateUpcomingRoutes(routes))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Form)