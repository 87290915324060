import React from 'react'
import {locationFormatter,translate} from '../../shared/utility'

import BicycleGreen from '../../assets/bicycle_green.svg'
import BicycleYellow from '../../assets/bicycle_yellow.svg'
import BicycleRed from '../../assets/bicycle_red.svg'

import classes from './FahrtenListItem.module.css'

const FahrtenListItem = (props) => {

    let countElement = null

    if(props.count <= 5){
        countElement = <li className={[classes.ItemRightEnd,classes.Green].join(" ")}>{props.capacity - props.count}<img src={BicycleGreen}/></li>
    }else if(props.count <= 10){
        countElement = <li className={[classes.ItemRightEnd,classes.Yellow].join(" ")}>{props.capacity - props.count}<img src={BicycleYellow}/></li>
    }else{
        countElement = <li className={[classes.ItemRightEnd,classes.Red].join(" ")}>{props.capacity - props.count}<img src={BicycleRed}/></li>
    }
         
            
            
        
            

            
    

    return (
      <div className={classes.FahrtenListItem}>
          <div className={classes.Upper}>
              <ul>
                  <li className={classes.IdItem}>{props.id}</li>                  
                  <li>{props.end}</li>
                  <li className={classes.ItemRight}>{props.hour < 10 ? "0"+props.hour:""+props.hour}:{props.minute < 10 ? "0"+props.minute:""+props.minute}</li>
                  {countElement}
              </ul>
          </div>
          {props.currentLocation !== "" ? <div className={classes.Lower}><p>{translate(props.lang,"* Aktueller Stand","* Current status")} - {locationFormatter(props.currentLocation)}</p></div>:null}
      </div>
    )

}
export default FahrtenListItem